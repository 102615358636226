<script setup lang="ts">
import { CheckCircleIcon } from "@heroicons/vue/24/outline";
import { XMarkIcon } from "@heroicons/vue/20/solid";
import type { Notification } from "~/composables/useNotification";

const props = withDefaults(defineProps<{
  delay?: number
}>(), {
  delay: 200
});

const fetching = ref(false);
const show = ref(false);

const {
  notifications,
  clearNotification
} = useNotification();

const currentNotification = ref<Notification>();

watch(notifications, (val) => {
  if (!currentNotification.value && val.length && !fetching.value) {
    currentNotification.value = val[0];
    show.value = true;
  }
}, { deep: true, immediate: true });

function closeNotification () {
  show.value = false;
}

function onAfterLeave () {
  clearNotification();
  currentNotification.value = undefined;

  if (notifications.value.length) {
    // Fetching acts as a lock to stop the watcher from populating the
    // currentError ref before the delay
    fetching.value = true;

    setTimeout(() => {
      currentNotification.value = notifications.value[0];
      show.value = true;
      fetching.value = false;
    }, props.delay);
  }
}

</script>

<template>
  <div
    aria-live="assertive"
    class="z-10 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end ">
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
        @after-leave="onAfterLeave">
        <div
          v-if="show"
          class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">
                  {{ currentNotification?.title }}
                </p>
                <p
                  v-if="currentNotification?.message"
                  class="mt-1 text-sm text-gray-500">
                  {{ currentNotification?.message }}
                </p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button
                  type="button"
                  class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  @click="closeNotification">
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
